.route-settings-container {
  width: 400px;
  box-sizing: border-box;
  height: 0;
  padding: 0.5rem;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: height linear 0.3s;
  z-index: 1100;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .route-settings-container.active {
    height: 90%; }
  .route-settings-container.mobile {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .route-settings-container .content {
    border-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eaeaea; }
  .route-settings-container .header {
    width: 100%;
    box-sizing: border-box;
    background-color: #333;
    border-radius: inherit;
    margin: 0 0 1rem 0;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff; }
    .route-settings-container .header button {
      color: #fff;
      background: none;
      border: none;
      font-size: var(--font-size-md); }
  .route-settings-container .apply-button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 40px;
    height: 38px;
    width: 100%;
    margin-top: 9px;
    transition: all .2s ease-out;
    background-color: #a5cc00;
    width: 90%;
    margin: 1rem 0; }
    .route-settings-container .apply-button:hover {
      color: #fff;
      background-color: #677f00; }
  .route-settings-container .settings-list {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%; }
    .route-settings-container .settings-list li {
      margin: 0 0.5rem;
      color: #333;
      list-style: none;
      border-top: 1px dashed #ccc; }
      .route-settings-container .settings-list li label {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between; }
        .route-settings-container .settings-list li label span.label {
          padding: 1rem 0;
          font-weight: 700;
          font-size: var(--font-size-sm);
          text-transform: capitalize; }
      .route-settings-container .settings-list li:nth-last-child(1) {
        border-bottom: 1px dashed #ccc; }
  .route-settings-container .radio-button-toggle {
    width: 100%;
    display: flex;
    padding: 0 1rem 1rem 1rem;
    box-sizing: border-box; }
    .route-settings-container .radio-button-toggle input[type="radio"] {
      display: none; }
      .route-settings-container .radio-button-toggle input[type="radio"] + label {
        background: #999;
        cursor: pointer;
        color: #333;
        display: inline-block;
        font-size: var(--font-size-md);
        font-weight: 700;
        padding: 5px;
        width: 50%;
        transition: background ease-in-out 0.3s;
        display: flex;
        align-items: center;
        justify-content: center; }
        .route-settings-container .radio-button-toggle input[type="radio"] + label:hover {
          background: #878787; }
        .route-settings-container .radio-button-toggle input[type="radio"] + label + input + label {
          float: right; }
        .route-settings-container .radio-button-toggle input[type="radio"] + label:nth-child(2) {
          border-radius: 40px 0 0 40px; }
        .route-settings-container .radio-button-toggle input[type="radio"] + label:last-child {
          border-radius: 0 40px 40px 0; }
      .route-settings-container .radio-button-toggle input[type="radio"]:checked + label {
        background: #333;
        color: #f4f4f4; }
        .route-settings-container .radio-button-toggle input[type="radio"]:checked + label:hover {
          background: #212121; }
