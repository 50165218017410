.slider {
  cursor: pointer;
  padding: 0;
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: #d5d5d5;
  border-radius: 8px;
  transition: all ease-in-out 0.2s; }

.slider::after {
  content: '';
  position: absolute;
  width: 17px;
  height: 17px;
  top: -2px;
  left: 0;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #595959;
  transition: all ease-in-out 0.2s; }

.checkbox:checked ~ .slider {
  background-color: #a5cc00; }

.checkbox:checked ~ .slider::after {
  background-color: #000;
  left: 13px; }

.checkbox {
  position: absolute;
  left: -999999px; }
