:root {
  --font-size-base: 14.4px;
  --font-scale: 1.2;
  --line-height-base: 1.5;
  --line-height-dense: 1.2;
  --line-height-spaced: 1.8;
  --font-size-sm: calc(var(--font-size-base) / var(--font-scale));
  --font-size-md: calc(var(--font-size-base) * var(--font-scale));
  --font-size-lg: calc(var(--font-size-base) * var(--font-scale) * var(--font-scale));
  --font-size-xl: calc(var(--font-size-base) * var(--font-scale) * var(--font-scale) * var(--font-scale));
  --font-primary: "Helvetica Neue", Helvetica, Arial, sans-serif; }

h1 {
  font-size: var(--font-size-xl); }

h2 {
  font-size: var(--font-size-md); }

h3 {
  font-size: var(--font-size-md);
  font-weight: 500; }

h4 {
  font-size: var(--font-size-md); }

button, input {
  font-size: var(--font-size-base); }

@media print {
  #app > :not(.popup) > :not(.print-container) > :not(.print-area) > :not(.print-preview) {
    display: none !important; }
  .print-container {
    overflow: visible !important;
    display: block !important; }
    .print-container header,
    .print-container footer,
    .print-container aside {
      display: none; }
    .print-container .print-area .print-controls,
    .print-container .print-area .print-banner-ad-container {
      display: none; }
    .print-container .print-area .print-preview {
      border: none !important; }
      .print-container .print-area .print-preview .content {
        break-after: page; }
      .print-container .print-area .print-preview .print-map {
        display: block !important;
        break-inside: avoid;
        position: relative;
        height: 688px;
        width: 100%; }
        .print-container .print-area .print-preview .print-map > div {
          break-before: always !important;
          display: block !important; }
          .print-container .print-area .print-preview .print-map > div > div {
            break-before: page !important; }
    .print-container .print-notes {
      border: none;
      font-family: var(--font-primary);
      padding-left: 0; }
      .print-container .print-notes::before {
        content: 'Personal Notes';
        display: block;
        font-weight: bold;
        margin-bottom: 8px; }
      .print-container .print-notes.print-notes-hidden {
        display: none; } }

.print-container {
  width: 100%;
  height: 100%;
  overflow: auto; }
  .print-container .print-left-rail {
    display: flex;
    flex-direction: row; }
  .print-container > * {
    letter-spacing: 0.5px;
    color: #333; }
  .print-container aside {
    background: #eaeaea; }
  .print-container footer {
    background: #eaeaea;
    padding: 1em;
    grid-column: 1/4;
    padding-left: 250px; }

@media screen and (max-width: 1024px) and (orientation: portrait) {
  .print-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; }
    .print-container footer {
      display: none; }
    .print-container .print-area {
      height: 100%;
      overflow: hidden auto; }
      .print-container .print-area .print-controls {
        padding: 0; }
      .print-container .print-area .print-button {
        position: fixed;
        bottom: 30px;
        z-index: 1;
        border: none;
        border-radius: 999px;
        padding: 1rem;
        box-shadow: 0px 5px 5px 0px rgba(105, 105, 105, 0.55);
        background: #2498c2;
        right: 50px;
        color: #fff; }
        .print-container .print-area .print-button i {
          margin: 0;
          color: #333; }
      .print-container .print-area .back-button {
        position: fixed;
        bottom: 30px;
        z-index: 1;
        border: none;
        border-radius: 999px;
        padding: 1rem;
        box-shadow: 0px 5px 5px 0px rgba(105, 105, 105, 0.55);
        background: #eaeaea;
        left: 50px; }
        .print-container .print-area .back-button i {
          margin: 0;
          color: #333; }
    .print-container .print-header-logo {
      width: auto; }
    .print-container .print-header-title {
      padding: 0 1rem; }
    .print-container .print-header img {
      padding: 1rem;
      margin: 0;
      height: 25px;
      width: 80px; }
  .popup .print-area {
    height: 100%;
    width: 100%;
    overflow: hidden auto; } }

.print-container > * {
  box-sizing: border-box; }

.route-planner-print-trip-detail-container {
  display: flex;
  flex-direction: column; }
  .route-planner-print-trip-detail-container .top-half {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc; }
    .route-planner-print-trip-detail-container .top-half .trip-detail {
      display: flex;
      flex-direction: column; }
      .route-planner-print-trip-detail-container .top-half .trip-detail h2 {
        font-size: var(--font-size-xl);
        margin: 0; }
      .route-planner-print-trip-detail-container .top-half .trip-detail div {
        display: flex;
        flex-direction: row;
        margin-bottom: 3px;
        align-items: flex-end; }
        .route-planner-print-trip-detail-container .top-half .trip-detail div p {
          padding-top: 12px;
          padding-right: 8px;
          font-size: var(--font-size-sm);
          margin: 0; }
        .route-planner-print-trip-detail-container .top-half .trip-detail div p:nth-of-type(2) {
          font-size: var(--font-size-sm);
          font-weight: 700;
          padding: 12px 0 0 0;
          margin: 0; }
    .route-planner-print-trip-detail-container .top-half .notes-container {
      width: 300px;
      height: 52px;
      border-radius: 5px;
      margin-top: -10px; }
  .route-planner-print-trip-detail-container .bottom-half {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .route-planner-print-trip-detail-container .bottom-half .fuel-container {
      display: flex;
      flex-direction: row;
      font-size: var(--font-size-sm); }
      .route-planner-print-trip-detail-container .bottom-half .fuel-container p {
        margin-top: 5px; }
        .route-planner-print-trip-detail-container .bottom-half .fuel-container p:nth-of-type(2n) {
          font-weight: 700;
          padding: 0 5px; }
    .route-planner-print-trip-detail-container .bottom-half .qr-container {
      display: flex;
      flex-direction: row;
      font-size: var(--font-size-sm); }
      .route-planner-print-trip-detail-container .bottom-half .qr-container p {
        width: 110px;
        text-align: right;
        margin-top: 5px; }

.left-rail,
.right-rail {
  background: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 225px; }

.right-rail {
  width: 100%;
  padding-top: 90px; }

.left-rail .print-options {
  display: flex;
  flex-direction: column;
  width: 207px;
  color: #777; }
  .left-rail .print-options .directions-map-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 128px;
    box-sizing: border-box;
    padding-top: 20px;
    cursor: pointer; }
    .left-rail .print-options .directions-map-container p {
      font-weight: 600;
      font-size: var(--font-size-md); }
    .left-rail .print-options .directions-map-container i {
      height: 51px;
      width: 51px;
      border-radius: 25px;
      background-color: #000;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center; }
      .left-rail .print-options .directions-map-container i:before {
        height: 22px;
        width: 23px;
        padding-left: 5px;
        padding-bottom: 2px;
        font-size: 23px;
        padding-left: 2px;
        padding-bottom: 11px; }
    .left-rail .print-options .directions-map-container .icon-container {
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .left-rail .print-options .directions-map-container .icon-container div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        color: #777; }
  .left-rail .print-options .active-directions-map {
    transition: all ease-in-out 0.5s;
    background-color: white; }
    .left-rail .print-options .active-directions-map i {
      background-color: #2498c2; }
  .left-rail .print-options .helpful-hints-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px dashed #999;
    border-top: 1px dashed #999; }
    .left-rail .print-options .helpful-hints-container p {
      font-size: var(--font-size-md);
      font-weight: 500;
      color: #333; }
  .left-rail .print-options .text-size-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; }
    .left-rail .print-options .text-size-container p {
      font-weight: 500;
      color: #333;
      text-decoration: underline;
      cursor: pointer; }
      .left-rail .print-options .text-size-container p:first-of-type {
        text-decoration: none;
        font-size: var(--font-size-md);
        cursor: default; }
      .left-rail .print-options .text-size-container p:nth-of-type(2) {
        font-size: var(--font-size-sm); }
      .left-rail .print-options .text-size-container p:nth-of-type(3) {
        font-size: var(--font-size-md); }
      .left-rail .print-options .text-size-container p:nth-of-type(4) {
        font-size: var(--font-size-lg); }
    .left-rail .print-options .text-size-container .active-text {
      text-decoration: none;
      color: #677f00; }

.left-rail button {
  border: none;
  background: none;
  font-weight: 600;
  padding: 1rem;
  cursor: pointer;
  outline-color: #a5cc00; }

.left-rail .print-back-button {
  text-transform: uppercase;
  height: 92px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0; }
  .left-rail .print-back-button i {
    margin-right: 0.6rem;
    padding-bottom: 20px; }
  .left-rail .print-back-button p {
    text-decoration: underline;
    font-weight: 400;
    padding-bottom: 20px; }

.left-rail .print-reset-button {
  opacity: inherit; }

.left-rail .left-ad-unit {
  min-width: 160px;
  min-height: 600px; }

.left-rail .print-reset-button {
  padding: 0;
  color: #677f00; }

.print-text-size-small {
  font-size: var(--font-size-sm); }

.print-text-size-medium {
  font-size: var(--font-size-md); }

.print-text-size-large {
  font-size: var(--font-size-lg); }

.print-area {
  background: #f4f4f4;
  min-width: 728px;
  display: flex;
  flex-direction: column; }
  .print-area .print-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #eaeaea; }
  .print-area .print-button {
    margin-top: 20px;
    font-size: var(--font-size-md);
    color: black;
    background: #a5cc00;
    padding: 1rem 3rem;
    border-radius: 40px;
    border: none;
    cursor: pointer; }
    .print-area .print-button i {
      font-size: 1em;
      margin-right: 0.5rem;
      color: #fff; }
  .print-area .print-banner-ad-container {
    display: flex;
    flex-direction: column;
    background: #eaeaea;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 0; }
  .print-area .print-preview {
    padding: 1rem;
    background: #fff;
    border: 3px dashed #d5d5d5; }
    .print-area .print-preview .content {
      padding: 1rem; }
    .print-area .print-preview .print-map > div {
      position: relative; }
      .print-area .print-preview .print-map > div > div:first-child {
        width: 100%;
        height: 728px; }

.print-header {
  grid-column: 1/4;
  display: flex;
  background: #333;
  align-items: center;
  height: 68px; }
  .print-header-logo {
    width: 250px; }
  .print-header img {
    display: block;
    height: 50px;
    width: 160px;
    padding: 0 3rem 0 1rem;
    margin-top: 10px; }
  .print-header-title {
    color: #fff;
    padding: 0 3rem;
    border-left: 1px solid #d5d5d5;
    box-sizing: border-box; }

.print-notes {
  border-radius: 5px;
  border: 1px solid #666;
  display: block;
  margin: 10px 0;
  outline-color: #a5cc00;
  padding: 5px 10px;
  resize: none;
  font: 400 var(--font-size-base) monospace; }
  .print-notes[contenteditable]:empty::before {
    content: 'You can type personal notes here to include on your printed notes';
    color: #777; }

.print-infosheet {
  display: flex;
  flex-direction: column; }
  .print-infosheet img {
    max-width: 240px; }
  .print-infosheet .print-infosheet-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    .print-infosheet .print-infosheet-header h2 {
      margin: 0; }
    .print-infosheet .print-infosheet-header p,
    .print-infosheet .print-infosheet-header span {
      margin: 5px 8px 5px 0;
      color: #777;
      font-weight: 400; }
    .print-infosheet .print-infosheet-header img {
      width: 160px; }
    @media only screen and (max-width: 689px) {
      .print-infosheet .print-infosheet-header {
        display: flex;
        flex-direction: column-reverse; }
        .print-infosheet .print-infosheet-header > div {
          display: flex;
          flex-direction: column; } }
  .print-infosheet .about-container {
    padding: 0; }
    .print-infosheet .about-container .about-header {
      background-color: #fff;
      border: 1px solid #ccc;
      color: #333; }
    .print-infosheet .about-container .about-block {
      margin-bottom: 8px; }
    .print-infosheet .about-container .about-verified {
      border: none; }
  .print-infosheet .print-infosheet-gas > div {
    margin: 0;
    padding: 0;
    font-weight: 400; }
  .print-infosheet .print-infosheet-description .about-header {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333; }
  .print-infosheet .print-infosheet-description .about-description {
    padding: 0; }
  .print-infosheet .print-infosheet-description section {
    padding: 0; }
  .print-infosheet .hide {
    visibility: hidden; }

button.print-button.routeplanner-print-button {
  background: #2498c2;
  color: #fff; }

.adsDoNotPrintText {
  font-size: var(--font-size-sm);
  color: #333;
  opacity: 0.6; }
