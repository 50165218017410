.menu {
  background-color: #424242;
  width: 100%;
  max-width: 400px;
  height: 100%;
  transition: margin-left 0.3s ease-in-out;
  letter-spacing: 0.6px;
  z-index: 3;
  position: fixed;
  overflow: scroll;
  overflow-x: hidden;
  top: 0; }

.menuClosed {
  margin-left: -400px; }

.menuOpen {
  margin-left: 0; }

.close-nav-icon {
  height: 25px;
  width: 25px;
  transition: color 0.5s;
  background: #000;
  color: #fff;
  position: absolute;
  border: 0 solid #fff;
  border-radius: 40px;
  left: 7px;
  top: 10px; }
  .close-nav-icon:focus {
    outline: none; }
  .close-nav-icon:hover {
    cursor: pointer; }

.top-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #a5cc00;
  font-weight: 300;
  margin: 0 0 0 20px;
  box-sizing: border-box;
  font-size: var(--font-size-md); }
  @media screen and (min-width: 690px) {
    .top-block {
      font-size: var(--font-size-lg); } }
  .top-block img {
    width: 145px;
    height: 16px;
    margin: 6px 0 6px 150px; }
  .top-block a {
    text-decoration: none;
    color: #a5cc00; }
    .top-block a span {
      transition: color 0.1s ease;
      box-sizing: border-box;
      cursor: pointer; }
      .top-block a span:hover {
        color: #fff; }
  .top-block aside {
    color: #fff;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    font-weight: 200;
    margin-left: 5px; }

.secondary-block,
.termsBlock {
  display: flex;
  flex-direction: column;
  border-top: 1px dashed #777;
  color: #999;
  padding: 10px 0;
  margin: 10px 20px;
  box-sizing: border-box;
  font-weight: 200;
  font-size: var(--font-size-md); }
  .secondary-block:nth-of-type(4),
  .termsBlock:nth-of-type(4) {
    margin-bottom: 0;
    padding-bottom: 1px; }
  .secondary-block:nth-of-type(5),
  .termsBlock:nth-of-type(5) {
    margin-bottom: 0;
    padding-bottom: 1px; }
  .secondary-block a,
  .termsBlock a {
    text-decoration: none;
    letter-spacing: 1px; }
    .secondary-block a:visited,
    .termsBlock a:visited {
      text-decoration: none; }
  .secondary-block span,
  .termsBlock span {
    color: #a5cc00; }
    .secondary-block span:hover,
    .termsBlock span:hover {
      color: #fff; }
  .secondary-block .img-container,
  .termsBlock .img-container {
    height: 80px;
    margin-top: 5px; }
    .secondary-block .img-container img,
    .termsBlock .img-container img {
      width: 140px;
      height: 45px;
      margin-top: -8px;
      margin-right: 4px; }
  .secondary-block .nav-intro,
  .termsBlock .nav-intro {
    color: #fff;
    margin-bottom: 5px; }
    .secondary-block .nav-intro a,
    .termsBlock .nav-intro a {
      text-decoration: none;
      color: #a5cc00; }
      .secondary-block .nav-intro a:visited,
      .termsBlock .nav-intro a:visited {
        text-decoration: none; }
      .secondary-block .nav-intro a:hover,
      .termsBlock .nav-intro a:hover {
        color: #fff; }

.termsBlock a {
  text-decoration: none; }
  .termsBlock a span {
    color: #777;
    letter-spacing: 1px; }
    .termsBlock a span:hover {
      color: #fff; }
  .termsBlock a:visited {
    text-decoration: none; }

.flex-row {
  display: flex;
  flex-direction: row; }

.sticky-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  padding: 8px 0px;
  background-color: #424242; }
